import React, { Suspense } from 'react';
import { Helmet } from "react-helmet";
import '@fortawesome/fontawesome-free/css/all.min.css';

import LoadingMask, { LoadingText } from "./components/LoadingMask/LoadingMask";
import MainDashboard from './pages/MainDashBoard/MainDashboard';
import { MiniPlayerContainer } from "@falconstudios/ns-player";

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import Div100vh from 'react-div-100vh';
import { cleanupToken, hasTokenExpired } from './services/token-service/token.service';
import { env } from './env';


import './styles/main.scss';
import './App.scss';
import './overrides/overrides.css';
import ImagesLightBoxStyle from './components/ImagesLighbox/ImagesLightBoxStyle';
import { HtmlContentContextProvider } from './context/HtmlContentContext';

//get site name to create class and remove . from name below
const reactappsitename = env.REACT_APP_SITE_NAME

function App() {
  if (hasTokenExpired()) {
    cleanupToken();
  }

  return (
    <div className={`${reactappsitename.replace(/\./g, '')}`}>
      <Div100vh>
        <div className="App">
          <Helmet>
            <title>{env.REACT_APP_SITE_TITLE}</title>
            <meta name="description" content={env.REACT_APP_SITE_DESCRIPTION} />
          </Helmet>
          <Suspense fallback={<LoadingText />}> {/* first loader is in index.html */}
            <HtmlContentContextProvider>
              <MainDashboard />
            </HtmlContentContextProvider>
          </Suspense>
          <MiniPlayerContainer />

          <LoadingMask /> {/* log in / out*/}

          <ImagesLightBoxStyle /> {/* MovieDetailsPage image gallery (fas fa-camera icon) */}

        </div>
      </Div100vh>
    </div>
  );
}

export default App;
