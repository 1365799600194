import React, { useEffect, useState, useRef } from "react";
import { DashboardController } from "../../../controllers/dashboard-controller/DashboardController";
import { getScenes } from "../../../services/scenes-service/scenes.service";

import NewToday from "./NewToday/NewToday";

import "./MainDashboardView.scss";

import "../../../components/BaseSectionHeader/BaseSectionHeader.scss";

import StudioDetailsPageMovieSiteSpecific from "./SiteSpecific/StudioDetailsPageMovieSiteSpecific.js";

import { stringToSlug } from "../../../services/util-service/util.service";
import { ROUTES } from "../../../services/navigation/navigation.routes";

import BannerSetViewFunc from "../../../components/BannerSetView/BannerSetViewFunc";
import { RenderConditionalComponent, PROPERTY_ID } from '../../../components/RenderConditionalComponent/RenderConditionalComponent';
import MoviesScenesGrid from "../../../components/MoviesScenesGrid/MoviesScenesGrid";
import useUserStateChange from "../../../hook/useUserStateChange";
import useInViewport from "../../../hook/useInViewport";

import MostPopular from "./MostPopular/MostPopular";
import FeaturedRelease from "./FeaturedRelease/FeaturedRelease";

const MainDashboardView = () => {
	const [state, setState] = useState({
		config: {},
		videos: [],
		viewAllLink: '',
		loading: true,
		error: false
	});
	const [logged] = useUserStateChange();

	const {
		config,
		videos = [],
		viewAllLink,
		loading,
		error
	} = state;

	const containerRef = useRef(null);
	const isVisible = useInViewport(containerRef);

	useEffect(() => {
		if (!isVisible) return;

		const config = DashboardController.getHomePageSpotlight();
		if (config && Object.keys(config).length !== 0) {
			let filter = Object.entries(config)[0];
			if (filter?.length) {
				const { viewAllLink, ...filterObject } = processFilters(filter);
				const [, config] = filter;

				getScenes(filterObject)
					.then((resp = {}) => {
						const { scenes = [] } = resp.data?.data;
						setState(prevState => ({
							...prevState,
							videos: scenes,
							config: config || {},
							viewAllLink
						}));
					})
					.catch(err => {
						console.log(err);
						setState(prevState => ({ ...prevState, error: true }));
					})
					.finally(() => setState(prevState => ({ ...prevState, loading: false })));
			}
		} else {
			setState(prevState => ({
				...prevState,
				loading: false
			}));
		}
		return () => {
			setState({});
		}

	}, [isVisible]);

	const processFilters = (filter) => {
		const [type, config] = filter;
		const { id, name } = config;
		const data = { sort_by: 'newest' };
		switch (type) {
			case "studio":
				const studioLink = ROUTES.studiosInfo.replace(':studioId', id).replace(':studioName', name);
				Object.assign(data, { viewAllLink: studioLink, studios_id: id });
				break;
			case "star":
				const starLink = ROUTES.starInfo.replace(':starId', id).replace(':starName', name);
				Object.assign(data, { viewAllLink: starLink, stars_id: id });
				break;
			case "theme":
				const themeLink = ROUTES.themeInfo.replace(':name', name);
				Object.assign(data, { viewAllLink: themeLink, tags_id: id });
				break;
			default:
				break;
		}
		return data;
	};

	const renderVideos = () => {
		const link = stringToSlug(viewAllLink);

		return (
			<MoviesScenesGrid
				title={videos.length !== 0 ? 'Spotlight on ' + config?.name || '' : ''}
				viewAllLink={{ text: 'View All', url: link }}
				data={videos}
				loading={loading}
				error={error}
				hideNavigation
				refProp={containerRef}
			/>
		);
	};

	return (
		<div className="MainDashboardView">

			<NewToday hidePoster />

			<MostPopular />
			<StudioDetailsPageMovieSiteSpecific
				title="cocky boys"
				studioId="21970"
				studioName="cockboys" />
			<StudioDetailsPageMovieSiteSpecific
				title="eric videos"
				studioId="22988"
				studioName="EricVideos" />
			<StudioDetailsPageMovieSiteSpecific
				title="corbin fisher"
				studioId="21911"
				studioName="corbin-fisher" />

			<RenderConditionalComponent ids={[PROPERTY_ID.nakedSword, PROPERTY_ID.guerillaPorn]}>
				<FeaturedRelease />
			</RenderConditionalComponent>

			{renderVideos()}

			<BannerSetViewFunc bannerName='tour' />
			{!logged && <BannerSetViewFunc bannerName='nonMemberPromoAffiliate' />}
		</div>
	);
};

MainDashboardView.displayName = 'MainDashboardView';

export default MainDashboardView;